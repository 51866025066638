<template>
  <div style="padding: 10px 20px; overflow: scroll">
    <div v-if="!showWalletModal && !showTransactionHistory">
      <div class="site_container">
        <div class="left" @click="goBack">
          <img
            src="../../images/back.png"
            alt="Back Button"
            class="back-button"
          />
          <span class="text">Trade</span>
        </div>

        <div>
          <p style="margin: 0; font-size: 12px">
            Your Wallet Balance
            <span style="font-weight: 700; color: #453a94" v-if="walletBalance"
              >₹{{ walletBalance }}</span
            >
          </p>
          <p
            @click="openWalletModal"
            style="
              margin: 0;
              font-size: 14px;
              color: #453a94;
              font-weight: 700;
              text-align: right;
            "
          >
            View Wallet
          </p>
        </div>
      </div>

      <div>
        <div style="display: flex; justify-content: center; margin-top: 50px">
          <div class="grey-container">
            <div class="button-wrapper">
              <div class="slider" :class="sliderClass"></div>
              <button
                class="grey-button"
                @click="toggleSection('buy')"
                :class="{ active: showsell }"
              >
                Buy
              </button>
              <button
                class="grey-button"
                @click="toggleSection('sell')"
                :class="{ active: showbuy }"
              >
                Sell
              </button>
            </div>
          </div>
        </div>
        <div class="peer">
          <p v-if="showsell">
            Trade excess energy peer to peer for additional benefits.
          </p>
          <p v-if="showbuy">
            Save money by buying energy at competitive prices directly from
            producers.
          </p>
        </div>

        <div v-if="showsell">
          <div class="sell_cards-container">
            <div class="sell_card">
              <div class="sell_card_head">
                <h2>Available</h2>
                <p>
                  {{
                    prodSoldAvaData.available ? prodSoldAvaData.available : 0
                  }}
                  <span>kWh</span>
                </p>
              </div>
              <img src="../../images/rectangle.png" class="sell_card-image" />
            </div>
            <div class="sell_card">
              <div class="sell_card_head">
                <h2>Produced</h2>
                <p>
                  {{ prodSoldAvaData.produced ? prodSoldAvaData.produced : 0 }}
                  <span>kWh</span>
                </p>
              </div>
              <img src="../../images/rectangle.png" class="sell_card-image" />
            </div>
            <div class="sell_card">
              <div class="sell_card_head">
                <h2>Sold</h2>
                <p>
                  {{ prodSoldAvaData.sold ? prodSoldAvaData.sold : 0 }}
                  <span>kWh</span>
                </p>
              </div>
              <img src="../../images/rectangle.png" class="sell_card-image" />
            </div>
          </div>
        </div>
        <div class="card-container">
          <div class="card-header">
            <span class="card-heading">Savings Calculator</span>
            <img
              src="../../images/buy_down.png"
              alt="Dropdown"
              @click="toggleRangeSelector"
              class="dropdown-icon"
              style="height: 9px; width: 14px"
            />
          </div>
          <div v-if="showRangeSelector" class="range-selector">
            <p :style="{ color: violetColor }">
              {{ rangeValue }} kWh <span>per day</span>
            </p>
            <input
              type="range"
              min="0"
              :max="maxValue"
              :step="step"
              v-model="rangeValue"
              @input="updateVioletFill"
              :style="rangeBackgroundStyle"
            />
            <div class="text-section">
              <div class="text-buttons">
                <p>In the period of</p>
                <div class="button-group">
                  <button
                    @click="setPeriod('1m')"
                    :class="{ active: period === '1m' }"
                  >
                    1 Month
                  </button>
                  <button
                    @click="setPeriod('6m')"
                    :class="{ active: period === '6m' }"
                  >
                    6 Months
                  </button>
                  <button
                    @click="setPeriod('1y')"
                    :class="{ active: period === '1y' }"
                  >
                    1 Year
                  </button>
                </div>
              </div>
              <div v-if="showsell" class="sell-headings">
                <div class="purchase_card_sell">
                  <div>
                    <p class="purchase">You have earned</p>
                    <p class="save fifty">₹{{ savedMoney }}</p>
                  </div>
                  <div>
                    <img src="../../images/ruppee2.svg" alt="" />
                  </div>
                </div>
              </div>
              <div v-if="!showsell" class="sell-headings">
                <div class="purchase_card">
                  <div>
                    <p class="purchase">
                      Total purchase of {{ rangeValue }} kWh
                    </p>
                    <p class="save">
                      You will save <span>₹ {{ earnedMoney }} </span>
                    </p>
                  </div>
                  <div>
                    <img src="../../images/ruppee2.svg" alt="" />
                  </div>
                </div>
              </div>
              <button
                class="set_up_auto"
                @click="handleAutoPurchase"
                :disabled="autoPurchaseLoading"
              >
                {{
                  autoPurchaseLoading
                    ? "Loading..."
                    : showbuy
                    ? `${autoPurchaseBtnText} Auto Purchase`
                    : `${autoSellBtnText} Auto Sell`
                }}
              </button>
              <div
                v-if="showModal"
                class="modal-overlay"
                @click.self="closeModal"
              >
                <div class="modal-content">
                  <div v-if="!showAutoPurchaseSetup">
                    <img
                      src="../../images/modal_ruppe.png"
                      alt="Center Image"
                      class="modal-image"
                    />
                    <p class="setup_one">
                      Simplify Your Payment <br />With Autopay
                    </p>
                    <p class="setup_two">
                      Allow us to easily invest and save your money without
                      manual payments.
                    </p>
                    <p class="setup_three">Activate Auto pay</p>
                    <button
                      class="modal-setup-button"
                      @click="showAutoPurchase"
                    >
                      Set Up
                    </button>
                  </div>
                  <AutoPurchase
                    v-if="showAutoPurchaseSetup"
                    :mode="showbuy ? 'purchase' : 'sell'"
                    :prefilledData="showbuy ? autoPurchaseData : autoSellData"
                    @close-modal="showAutoPurchaseSetup = false"
                    @return="
                      async () => {
                        showAutoPurchaseSetup = false;
                        closeModal();
                        await fetchAutoPurchase_Sell();
                      }
                    "
                  />

                  <div v-if="showAutoPurchaseSetup">
                    <button
                      class="cancel-button"
                      @click="closeshowAutoPurchase"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="active_transaction">
          <div>
            <hr />
            <h1 class="active-head">Active Transaction</h1>
            <div v-if="loading">Loading....</div>
            <div v-else>
              <div
                v-if="activeTransaction.length > 0"
                v-for="(card, index) in activeTransaction"
                class="recommended-units-card unit_active"
              >
                <div class="left-unit-card">
                  <h1>{{ card.actualTraded }} Units</h1>
                  <div class="time_date">
                    <div style="display: flex; gap: 2px">
                      <img src="../../images/date.svg" alt="Date" />
                      <p>
                        {{
                          card.startDate
                            ? new Intl.DateTimeFormat("en-US", {
                                day: "numeric",
                                month: "short",
                              }).format(new Date(parseInt(card.startDate, 10)))
                            : "N/A"
                        }}
                      </p>
                    </div>
                    <div style="display: flex; gap: 2px">
                      <img src="../../images/clock.svg" alt="Clock" />
                      <p>{{ card.time  ? card.time :"N/A"}}</p>
                    </div>
                  </div>
                  <p class="you_save">
                    {{
                      card.saveMessage
                        ? card.saveMessage
                        : "You save ₹ 300 on this purchase"
                    }}
                  </p>
                </div>
                <div class="right-unit-card">
                  <p>₹ {{ card.amount }}</p>
                  <p>per unit ₹{{ card.unitPrice ? card.unitPrice : "5" }}/-</p>
                  <button class="transaction_status">{{ card.status }}</button>
                </div>
              </div>
              <div v-else class="empty-state">
                <img
                  src="../../images/no_transaction.png"
                  alt="No Active Transactions"
                  class="empty-icon"
                />
                <p>No active transactions at the moment</p>
              </div>
            </div>

            <router-link
              to="#"
              @click.prevent="showTransactionHistory = true"
              class="no_tran"
            >
              <button class="view_history">View Transaction History</button>
            </router-link>
          </div>
        </div>

        <hr />
        <div class="recommended-container">
          <div class="recommended-left-content">
            <h1>Recommended</h1>
            <div class="info-container">
              <img src="../../images/i.svg" alt="Info Icon" class="info-icon" />
              <p>Based on your consumption pattern</p>
            </div>
          </div>

          <div class="recommended-right-content">
            <!--            <button @click="fetchRecommends()" class="recommended-action-button">-->
            <!--&lt;!&ndash;              <img src="../../images/refresh.svg" alt="">&ndash;&gt;-->
            <!--            </button>-->
          </div>
        </div>
        <div class="days-dropdown-container">
          <select
            id="date-select"
            v-model="selectedDay"
            @change="fetchRecommends"
          >
            <option value="today">Today</option>
            <!--            <option value="tomorrow">Tomorrow</option>-->
            <!--            <option value="month">This Month</option>-->
          </select>
        </div>
        <div>
          <div v-if="loading">Loading....</div>
          <div v-else>
            <div v-if="cards.length === 0" class="empty-state">
              <img
                src="../../images/no_data.png"
                alt="No Data"
                class="empty-state-image"
              />
              <p class="empty-state-text">
                No Data available. Please check back later!
              </p>
            </div>
            <div class="unit-cards">
              <div
                class="recommended-units-card"
                v-for="(card, index) in cards"
                :key="index"
              >
                <div class="left-unit-card">
                  <h1>
                    {{ card.actualTraded }} Units
                    <span style="font-size: 12px; font-weight: 500"
                      >( of {{ card.units }} available)</span
                    >
                  </h1>
                  <div class="time_date">
                    <div style="display: flex; gap: 5px">
                      <img src="../../images/date.svg" alt="Date" />
                      <p>
                        {{
                          card.startDate
                            ? new Intl.DateTimeFormat("en-US", {
                                day: "numeric",
                                month: "short",
                              }).format(new Date(parseInt(card.startDate, 10)))
                            : "N/A"
                        }}
                      </p>
                    </div>
                    <div style="display: flex; gap: 5px">
                      <img src="../../images/clock.svg" alt="Clock" />
                      <p>{{ card.time ? card.time :"" }}</p>
                    </div>
                  </div>
                  <p class="you_save">{{ "You save ₹300 on this purchase" }}</p>
                </div>
                <div class="right-unit-card">
                  <p>₹ {{ card.amount }}</p>
                  <p>per unit ₹{{ "5" }}/-</p>
                  <button @click="openBuyModal(card)">
                    {{ showbuy ? "Buy" : "Sell" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BuyModal
        v-if="showBuyModal"
        :card="selectedCard"
        :selectedDay="selectedDay"
        :show="showBuyModal"
        @close="closeBuyModal"
        :showbuy="showbuy"
      />
    </div>
    <TransactionHistory
      v-if="showTransactionHistory"
      @close="showTransactionHistory = false"
    />

    <ViewWallet :isVisible="showWalletModal" @close="closeWalletModal" />
  </div>
</template>

<script>
import AutoPurchase from "../components/AutoPurchase.vue";
import BuyModal from "../components/BuyModal.vue";
import ViewWallet from "@/components/ViewWallet.vue";
import UserService from "@/services/user.service";
import DataService from "@/services/data.service";
import TradeService from "@/services/trade.service";
import TransactionHistory from "@/components/TransactionHistory.vue";

export default {
  props: {
    goBack: Function,
  },
  components: {
    AutoPurchase,
    ViewWallet,
    BuyModal,
    TransactionHistory,
  },
  data() {
    return {
      showTransactionHistory: false,
      showModal: false,
      loading: true,
      showBuyModal: false,
      showWalletModal: false,
      isInAutoPurchase: false,
      selectedCard: null,
      period: "1m",
      pricePerUnit: 4,
      maxValue: 0,
      showAutoPurchaseSetup: false,
      selectedDay: "today",
      showbuy: true,
      earnedMoney: 0,
      savedMoney: 0,
      showsell: false,
      showRangeSelector: true,
      step: 1,
      rangeValue: 0,
      violetColor: "#453A94",
      walletBalance: "",
      prodSoldAvaData: {},
      cards: [],
      activeTransaction: [],
      autoPurchaseBtnText: "Set up",
      autoSellBtnText: "Set up",
      autoPurchaseLoading: true,
      autoPurchaseData: null,
      autoSellData: null,
      dayToNumber: {
        MONDAY: 0,
        TUESDAY: 1,
        WEDNESDAY: 2,
        THURSDAY: 3,
        FRIDAY: 4,
        SATURDAY: 5,
        SUNDAY: 6,
      },
    };
  },
  async mounted() {
    this.walletBalance = await UserService.getWalletBalance();
    this.prodSoldAvaData = await DataService.getAvailableProducedSoldData();
    await this.fetchAutoPurchase_Sell();
    await this.fetchRecommends();
    await this.getActiveTransaction();
    this.rangeValue = (90 * this.maxValue) / 100;
    this.step = (10 * this.maxValue) / 100;
  },
  computed: {
    sliderClass() {
      if (this.showbuy) return "slide-left";
      if (this.showsell) return "slide-middle";
      return "";
    },
    rangeBackgroundStyle() {
      const percentage = (this.rangeValue / this.maxValue) * 100;
      this.calculateMoney();
      return {
        background: `linear-gradient(to right, ${this.violetColor} ${percentage}%, #D9D4FF ${percentage}%)`,
      };
    },
  },
  methods: {
    async fetchAutoPurchase_Sell() {
      try {
        this.autoPurchaseLoading = true;
        const purchaseResponse = await TradeService.getAutoPurchaseInfo();
        if (purchaseResponse && purchaseResponse.length > 0) {
          this.autoPurchaseBtnText = "Modify";
          this.autoPurchaseData = purchaseResponse[0];
          this.autoPurchaseData.days = this.autoPurchaseData.days.map(
            (day) => this.dayToNumber[day]
          );
        }
        const sellResponse = await TradeService.getAutoSellInfo();
        if (sellResponse && sellResponse.length > 0) {
          this.autoSellBtnText = "Modify";
          this.autoSellData = sellResponse[0];
          this.autoSellData.days = this.autoSellData.days.map(
            (day) => this.dayToNumber[day]
          );
        }
        this.autoPurchaseLoading = false;
      } catch (e) {
        this.autoPurchaseLoading = false;

        throw new Error(e);
      }
    },
    async fetchRecommends() {
      try {
        let response;
        this.loading = true;
        if (!this.showsell) {
          response = await TradeService.getPurchaseInfo(this.selectedDay);
        } else {
          response = await TradeService.getSellInfo(this.selectedDay);
        }
        if (response) {
          this.pricePerUnit = response.pricePerUnit;
          this.maxValue = response.maxValuePerDay;
          this.cards = response.recommends;
          this.cards = this.cards.map((card) => {
              card.time = this.getTimeString(card.startDate,card.endTDate);
              return card;
          })
        }
      } catch (e) {
        throw new Error(e);
      }
      this.loading = false;
    },

    async handleAutoPurchase() {
      await this.fetchAutoPurchase_Sell();
      if (!this.showsell && this.autoPurchaseBtnText === "Modify") {
        this.showAutoPurchase();
        this.showModal = true;
      } else if (this.showsell && this.autoSellBtnText === "Modify") {
        this.showAutoPurchase();
        this.showModal = true;
      } else {
        this.showModal = true;
      }
    },

    async getActiveTransaction() {
    try {
        const response = await TradeService.listTransactionHistory();
        if (response) {
            const transactionType = !this.showsell ? "SELL" : "BUY";

            this.activeTransaction = response
                .map((transaction) => {
                    transaction.time = this.getTimeString(transaction.startDate, transaction.endTDate);
                    return transaction;
                })
                .filter((transaction) =>
                    transaction.type === transactionType && transaction.status === "CLAIMED"
                );
        }
    } catch (error) {
        console.log(error);
    }
},
    compareDate(isoString) {
      const isoDate = new Date(isoString);
      isoDate.setHours(0, 0, 0, 0);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      return isoDate.getTime() === currentDate.getTime();
    },

    getTimeString(startDate, endDate) {
    if (!startDate || !endDate) return "";
    const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true, 
    };
    const startTime = new Intl.DateTimeFormat("en-US", options).format(new Date(parseInt(startDate, 10)));
    const endTime = new Intl.DateTimeFormat("en-US", options).format(new Date(parseInt(endDate, 10)));
    return `${startTime} - ${endTime}`;
},

    closeModal() {
      this.showModal = false;
    },

    closeBuyModal() {
      this.showBuyModal = false;
    },

    showAutoPurchase() {
      this.showAutoPurchaseSetup = true;
    },

    closeshowAutoPurchase() {
      this.showModal = false;
      this.showAutoPurchaseSetup = false;
      this.fetchAutoPurchase_Sell();
    },

    async toggleSection(section) {
      this.resetMainButtons();
      if (section === "sell") {
        this.showsell = true;
        this.showbuy = false;
      } else if (section === "buy") {
        this.showbuy = true;
        this.showsell = false;
      }
      await this.fetchRecommends();
      await this.getActiveTransaction();
      this.step = (10 * this.maxValue) / 100;
      this.$nextTick(() => {
        this.rangeValue = (90 * this.maxValue) / 100;
      });
    },

    resetMainButtons() {
      this.showsell = false;
      this.showbuy = false;
    },

    toggleRangeSelector() {
      this.showRangeSelector = !this.showRangeSelector;
    },

    calculateMoney() {
      const multiplier =
        this.period === "1m" ? 30 : this.period === "6m" ? 180 : 365;
      const calculatedMoney = (
        this.rangeValue *
        multiplier *
        this.pricePerUnit
      ).toFixed(2);
      if (this.showsell) {
        this.savedMoney = calculatedMoney;
      } else {
        this.earnedMoney = calculatedMoney;
      }
    },

    setPeriod(period) {
      this.period = period;
      this.calculateMoney();
    },

    updateVioletFill() {
      this.violetColor = "#453A94";
    },

    openBuyModal(card) {
      this.selectedCard = card;
      this.showBuyModal = true;
    },

    openWalletModal() {
      this.showWalletModal = true;
    },

    closeWalletModal() {
      this.showWalletModal = false;
    },
  },
};
</script>

<style scoped>
.cancel-button {
  position: absolute;
  right: 25px;
  bottom: 18px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #ddd;
  padding: 12px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.site_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 999;

  .left {
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.view_wallet {
  display: flex;
  justify-content: end;
}

.no_view {
  text-decoration: none;
}

.grey-container {
  background-color: #f0f0f0;
  border-radius: 30px;
  margin-top: 20px;
  width: 80%;
}

.button-wrapper {
  position: relative;
  display: flex;
  background: #f3f1ff;
  padding: 5px;
  border-radius: 30px;
  overflow: hidden;
}

.button-wrapper .grey-button {
  flex: 1;
  color: #ffffff;
  margin: 4px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  border-radius: 30px;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
  z-index: 1;
}

.button-wrapper .grey-button.active {
  color: #000000;
  font-weight: 500;
}

.button-wrapper .slider {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  width: 50%;
  border-radius: 30px;
  background: linear-gradient(123.558deg, #050011 0%, #453a94 100%);
  transition: transform 0.3s;
  z-index: 0;
}

.button-wrapper .slider.slide-left {
  transform: translateX(0%);
}

.button-wrapper .slider.slide-middle {
  transform: translateX(93%);
}

.button-wrapper .slider.slide-right {
  transform: translateX(200%);
}

.peer p {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  padding: 10px 5px;
  color: #000;
  border: 1px dashed #ccc;
  margin-top: 15px;
  margin-bottom: 14px;
}

.card-container {
  margin-top: 10px;
  padding: 20px;
  background-color: #f3f1ff;
  border-radius: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-heading {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000000;
}

.range-selector {
  margin-top: 10px;
}

.range-selector input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  height: 5px;
  outline: none;
  opacity: 0.7;
  border-radius: 10px;
  transition: opacity 0.15s ease-in-out;
}
.range-selector p {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.range-selector p span {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.range-selector input[type="range"]::-webkit-slider-runnable-track,
.range-selector input[type="range"]::-moz-range-track {
  height: 5px;
}

.range-selector input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #d9d9d9;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: -7.5px;
}

.range-selector input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #d9d9d9 !important;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: -7.5px;
}

.text-section {
  margin-top: 15px;
}

.text-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 15px;
}

.text-buttons p {
  margin: 0;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
}

.button-group {
  display: flex;
  gap: 5px;
}

.button-group button {
  padding: 5px;
  border: 0.5px solid #aaaaaa;
  border-radius: 20px;
  color: #000000;
  cursor: pointer;
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
}

.button-group button:active {
  background-color: #00f2b2;
  border: 1px solid #00f2b2;
}

.button-group button.active {
  background-color: #00f2b2;
  border: 1px solid #00f2b2;
  color: #000;
}

hr {
  margin-top: 10px;
  border: 1px solid #ccc;
}

.purchase {
  text-align: left;
  font-size: 12px !important;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #656565;
}

.save {
  text-align: left;
  font-size: 14px;
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif;
  color: #000000;
}

.save span {
  color: #13b78c !important;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700 !important;
}

.range-selector input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  border-radius: 10px;
  transition: opacity 0.15s ease-in-out;
}

.range-selector input[type="range"]:hover {
  opacity: 1;
}

.range-selector input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #453a94 !important;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: -7.5px;
}

.range-selector input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #453a94 !important;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  top: -7.5px;
}

.range-selector input[type="range"]::-webkit-slider-runnable-track {
  /* background: linear-gradient(to right, violet 0%, violet calc(var(--value) * 1%), #ddd calc(var(--value) * 1%), #ddd 100%); */
  height: 5px;
}

.range-selector input[type="range"]::-moz-range-track {
  background: linear-gradient(
    to right,
    violet 0%,
    violet calc(var(--value) * 1%),
    #ddd calc(var(--value) * 1%),
    #ddd 100%
  );
  height: 5px;
}

.auto_purchase {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f1ff;
  border-radius: 15px;
  padding: 15px;
}

.auto_purchase p {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.setup {
  background-color: #453a94;
  color: #ffffff;
  border-radius: 40px;
  font-size: 12px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  padding: 4px 23px;
}

.recommended-left-content h1 {
  text-align: left;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-weight: 600;
  margin: 0;
}

.recommended-left-content p {
  text-align: left;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #656565;
  font-weight: 400;
  margin: 0;
}

.recommended-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  margin-top: 20px;
}

.recommended-left-content {
  display: flex;
  flex-direction: column;
}

.recommended-right-content {
  display: flex;
  align-items: center;
}

.recommended-action-button {
  background-color: #453a94;
  width: 28px;
  height: 27px;
  border-radius: 5px;

  display: flex;
  justify-content: center;
}

.info-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.info-icon {
  margin-right: 10px;
}

#date-select {
  display: flex;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  margin-top: 15px;
}

.unit-cards {
  margin-top: 15px;
}

.recommended-units-card {
  display: flex;
  justify-content: space-between;
  background-color: #f4f4f4;
  padding: 10px 10px 10px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.time_date {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 12px;
}

.time_date p {
  margin: 0;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #000 !important;
}

.left-unit-card h1 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  color: #000;
  text-align: left;
  font-weight: 600;
  padding-top: 10px;
}

.you_save {
  color: #00a86c;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  margin-top: 12px;
  text-align: left;
}

.right-unit-card {
  background: linear-gradient(179.761deg, #ffffff 0%, #e6e6e6 100%);
  border-radius: 15px;
  padding: 6px 10px;
}

.right-unit-card p:nth-child(1) {
  font-size: 16px;
  color: #453a94;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.right-unit-card p:nth-child(2) {
  font-size: 12px;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.right-unit-card button {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  background-color: #453a94;
  border-radius: 40px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 4px 30px;
}

.purchase_card_sell {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  border-radius: 15px;
  padding: 24px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover;
  width: 100%;
}

.purchase_card {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 15px;
  padding: 20px 15px 24px;
  min-height: 99px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover;
  width: 100%;
}
.purchase_card p {
  margin: 0;
}
.purchase_card div {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #ffffff;
}

.purchase,
.save {
  margin: 0;
  font-size: 12px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}
.purchase {
  font-weight: 400 !important;
}
.set_up_auto {
  background: #453a94;
  color: #ffff;
  font-size: 12px;
  padding: 9px 30px;
  border-radius: 7px;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.modal-image {
  width: 102px;
  height: 102px;

  margin-bottom: 20px;
  margin: auto;
}

.setup_one {
  font-size: 18px !important;
  margin-bottom: 0;
  margin-top: 20px;
  color: #000000;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.setup_two {
  font-size: 12px !important;
  margin-bottom: 20px;
  font-weight: 400 !important;
  color: #adadad;
  font-family: "Montserrat", sans-serif;
}
.setup_three {
  margin-bottom: 20px;
  font-size: 18px !important;
}
.modal-setup-button {
  background: #453a94;
  color: #ffffff;
  padding: 7px;
  border: none;
  width: 44%;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  margin: auto;
}

.sell_cards-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.sell_card {
  position: relative;
  background: #fff;
  border-radius: 15px;
  flex: 1;
  background: linear-gradient(to right, #050011, #120b41);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;

  .sell_card-image {
    position: absolute;
    top: 0;
    right: 1px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 12px;
  }

  .sell_card_head {
    h2 {
      font-size: 12px;
      font-family: "Montserrat";
      color: #00f2b2;
      text-align: left;
      margin: 0;
      padding-top: 3px;
    }

    p {
      font-size: 20px;
      font-family: "Montserrat";
      text-align: left;
      color: #ffffff;
      margin: 0;

      span {
        font-size: 10px;
        font-family: "Montserrat";
        text-align: left;
        color: #ffffff;
      }
    }
  }
}

.fifty {
  font-size: 24px;
  font-family: "Montserrat";
  color: #ffffff;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

.empty-state-image {
  width: 100%;
  height: auto;
}

.empty-state-text {
  font-size: 14px;
  font-weight: 600;
  color: #656565;
  margin-top: 10px;
}

.active-head {
  text-align: left;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #000;
  font-weight: 600;
  margin: 20px 10px 10px;
}

.view_history {
  font-size: 12px;
  background-color: #453a94;
  color: #ffffff;
  border-radius: 7px;
  text-align: left;
  padding: 4px 10px;
  margin: auto;
}

.unit_active {
  margin-bottom: 10px !important;
}

.no_tran {
  text-decoration: none !important;
}

.transaction_status {
  border-radius: 5px !important;
  background-color: rgb(220, 159, 46) !important;
}
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  color: #999;
}

.empty-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.empty-state p {
  font-size: 14px;
  color: #666;
  font-family: "Montserrat", sans-serif;
}
</style>
