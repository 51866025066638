<template>
  <div v-if="isModalOpen">
    <h1>Setup Auto {{ mode === "purchase" ? "Purchase" : "Sell" }}</h1>
    <p>Add {{ mode === "purchase" ? "purchase" : "sell" }} rule</p>

    <div class="date-picker-container">
      <input
        type="date"
        id="start-date"
        v-model="startDate"
        ref="startDateInput"
      />
      <span class="arrow">→</span>
      <input type="date" id="end-date" v-model="endDate" ref="endDateInput" />

    </div>

    <div class="card">
      <!-- <p class="card-heading">Units on daily basis</p>
      <input type="text" placeholder="Enter value" v-model="inputValue" /> -->
      <p  class="card-heading" :style="{ color: violetColor }">
        {{ inputValue ? inputValue :0 }}  units <span style="color: #000000">per day</span>
      </p>
      <input
          type="range"
          min="0"
          :max="100"
          :step="1"
          v-model="inputValue"
          @input="updateVioletFill"
          class="slider"
          :style="rangeBackgroundStyle"
      />

      <p class="card-heading">Days of the week:</p>
      <div class="days-of-week">
        <div
          v-for="(day, idx) in days"
          :key="day"
          :class="['day-circle', { selected: selectedDays.includes(idx) }]"
          @click="toggleDay(idx)"
        >
          {{ day }}
        </div>
      </div>

      <p class="card-heading">
        {{
          mode === "purchase"
            ? "Maximum Purchase Amount (MPA)"
            : "Minimum Selling Amount (MSA)"
        }}
      </p>

      <div class="input-container">
        <span class="currency-symbol">₹</span>
        <input
          type="text"
          placeholder="Enter amount"
          class="end_date"
          v-model="anotherInputValue"
        />
        <span class="unit-text">/ per unit</span>
      </div>
      <p
        v-if="inputValue && anotherInputValue && selectedDays.length > 0"
        class="units"
      >
        Setting-up {{ inputValue }} units with ₹{{ anotherInputValue }}/unit
        {{ mode === "purchase" ? "MPA" : "MSA" }}
        <span v-if="selectedDays.length < 7"
          >on every
          <span
            v-for="(day, index) in selectedDays.sort((a, b) => a - b)"
            :key="day"
          >
            {{ daysName[day]
            }}<span v-if="index < selectedDays.length - 2"> , </span>
            <span v-if="index == selectedDays.length - 2"> & </span> </span
          >.
        </span>
        <span v-else> everyday. </span>
      </p>

      <!-- Success Message -->
      <!-- <div v-if="success" class="alert alert-success">
        {{ success }}
      </div> -->

      <!-- Error Message -->
      <div v-if="error" class="alert alert-danger">
        {{ error }}
      </div>
      <!--
        <button class="add_rule">
            <img src="../../images/grey_plus.svg" alt=""> <span>Add Rule</span>

        </button>-->
    </div>

    <div class="buttons-container">
      <!-- <router-link to="/transaction"> -->
      <button v-if="!isLoading" class="save-button" @click="saveSettings">
        Save
      </button>
      <button v-else class="save-button">
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </button>
      <!-- </router-link> -->
      <!--        <button class="cancel-button" @click="cancel">Cancel</button>-->
    </div>
  </div>
</template>

<script>
import TradeService from "@/services/trade.service";
import { useToast } from 'vue-toastification'; 
export default {
  props: {
    mode: {
      type: String,
      default: "purchase",
    },
    prefilledData: Object,
  },

  data() {
    return {
      isModalOpen: true,
      violetColor: '#453a94',
      startDate: "",
      endDate: "",
      inputValue: 0,
      anotherInputValue: "",
      days: ["M", "T", "W", "Th", "F", "S", "Su"],
      selectedDays: [],
      daysName: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      isLoading: false,
      error: "",
      success: "",
    };
  },
  mounted() {
    if (this.prefilledData) {
      this.prefill();
    } else {
      this.initializeDates();
    }
  },
  methods: {
  rangeBackgroundStyle() {
    const percentage = (this.rangeValue / this.maxValue) * 100;
    this.calculateMoney();
    return {
      background: `linear-gradient(to right, ${this.violetColor} ${percentage}%, #D9D4FF ${percentage}%)`,
    };
  },
    openStartDatePicker() {
      this.$refs.startDateInput.focus();
    },
    openEndDatePicker() {
      this.$refs.endDateInput.focus();
    },
    prefill() {
      this.startDate =this.prefilledData.startDate
      this.endDate =this.prefilledData.endDate
      this.selectedDays = this.prefilledData.days;
      this.anotherInputValue = this.prefilledData.rate;
      this.inputValue = this.prefilledData.percentUnits;
    },
    initializeDates() {
      const randomDate = (start, end) =>
        new Date(
          start.getTime() + Math.random() * (end.getTime() - start.getTime())
        );
      const start = new Date();
      const end = new Date();
      end.setFullYear(end.getFullYear());
      this.startDate = randomDate(start, end).toISOString().split("T")[0];
      this.endDate = randomDate(start, end).toISOString().split("T")[0];
    },
    toggleDay(idx) {
      const index = this.selectedDays.indexOf(idx);
      if (index > -1) {
        this.selectedDays.splice(index, 1);
      } else {
        this.selectedDays.push(idx);
      }
    },
    async saveSettings() {
      this.isLoading = true;
      this.error="";
      const validation =
      this.startDate &&
      this.endDate &&
      this.inputValue &&
      this.selectedDays.length > 0 &&
      this.anotherInputValue;
      if (!validation) {
      this.isLoading = false;
      this.error = "All fields are required.";
      setTimeout(() => {
        this.error = "";
      }, 2000);
      return;
      }
      const data = {
      startDate: this.startDate,
      endDate: this.endDate,
      units: this.inputValue,
      days: this.selectedDays
        .sort((a, b) => a - b)
        .map((index) => this.daysName[index].toUpperCase()),
      rate: this.anotherInputValue,
      };
      try {
      let result;
      if (!this.prefilledData) {
        result = this.mode === "purchase"
        ? await TradeService.saveAutoPurchase(data)
        : await TradeService.saveAutoSell(data);
      } else {
        result = this.mode === "purchase"
        ? await TradeService.modifyAutoPurchase(data, this.prefilledData.id)
        : await TradeService.modifyAutoSell(data, this.prefilledData.id);
      }
      if (result) {
        this.isLoading = false;
        this.success = !this.prefilledData ? "Saved successfully." : "Updated successfully!";
        this.showToast("Success", this.success);
        this.$emit("return", false);
      }
      } catch (error) {
      this.isLoading = false;
      this.error = "Something went wrong.";
      console.log(error);
      }
    },
    showToast(title, message) {
      const toast = useToast(); 
      toast.success(message, {
        title: title,
        timeout: 2000, 
        position: 'top-right',
      });
    },
    cancel() {
      this.isModalOpen = false;
    },
    updateVioletFill() {
      this.violetColor = "#453A94";
    },

  },
};
</script>

<style scoped>
h1 {
  text-align: left;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  color: #453a94;
  font-weight: 600;
}
p {
  text-align: left;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-weight: 600;
}
.date-picker-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
  max-width: 300px;
  border-radius: 10px;
  border: 0.5px solid #bdbdbd;
  gap: 0;
}
.arrow {
  font-size: 19px;
}

.card {
  margin-top: 20px;
  border: none;
  padding: 15px;
  background-color: #F3F3F3;
  border-radius: 15px;
}
.card-heading {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #656565;
  margin: 0;
  margin-bottom: 10px;
}
.days-of-week {
  display: flex;
  gap: 3px;
  margin-bottom: 15px;
  font-size: 9px;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-weight: 700;
}
.day-circle {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}
.day-circle.selected {
  background-color: #453A94;
  color: white;
}
.buttons-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.save-button {
  background-color: #453a94;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}

.units {
  font-size: 12px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
input[type="text"],
input[type="date"] {
  margin-bottom: 15px;
  width: 75%;
  padding: 4px;
  border: 0.5px solid #aeaeae;
  border-radius: 7px;
}
input[type="date"] {
  margin-bottom: 0px;
    max-width: 110px;
    font-size: 12px;
    padding: 0 8px;
    box-sizing: border-box;
    text-align: center;
    color: #000000;
    background-color: transparent;
    border: none;
    outline: none;
}


.add_rule {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  gap: 12px;
  width: 40%;
  border-radius: 7px;
  padding: 10px 13px;
}

.input-container {
  display: flex;
  align-items: baseline;

  .end_date {
    width: 38% !important;
  }

  .currency-symbol {
    margin-right: 8px;
    font-size: 16px;
  }

  input {
    padding: 5px 10px;
    font-size: 13px;
  }

  .unit-text {
    margin-left: 8px;
    font-size: 14px;
  }
}

.alert {
  padding: 10px;
  border-radius: 7px;
  margin-top: 10px;
  font-size: 15px;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.slider{
  margin-bottom: 15px;
}
</style>
