<template>
  <div id="homePage">
    <component :is="currentView" :goBack="goBack"></component>

    <nav class="bottom-nav">
      <button @click="changeView('Home')" :class="{'active': currentView === 'Home'}" class="home-button">
        <img src="../../images/ichome.svg" alt="Home">
        <span>Home</span>
      </button>
      <button @click="changeView('SitePage')" :class="{'active': currentView === 'SitePage'}" class="home-button">
        <img src="../../images/analytics.svg" alt="Site">
        <span>Site</span>
      </button>

      <button v-if="showTradeTab" @click="changeView('BuySell')" class="home-button">
        <img src="../../images/trade_nav.png" alt="Trade">
        <span>Trade</span>
      </button>
      <button @click="changeView('Alerts')" class="home-button">
        <img src="../../images/alerts.svg" alt="Alerts">
        <span>Alerts</span>
      </button>

      <button @click="changeView('Settings')" :class="{'active': currentView === 'Settings'}" class="home-button">
        <img src="../../images/settings.svg" alt="Settings">
        <span>Settings</span>
      </button>
    </nav>
  </div>
</template>


<script>
import {ref, onMounted} from 'vue';
import Home from '../views/Home.vue';
import SitePage from './SitePage.vue';
import Alerts from '../views/Alert.vue';
import Settings from '../views/Settings.vue';
import BuySell from '../views/BuySell.vue';
import TradeService from '@/services/trade.service';

export default {
  components: {
    Home,
    SitePage,
    Alerts,
    Settings,
    BuySell
  },
  mounted() {

  },
  setup(props, {emit}) {
    const currentView = ref('Home');
    const history = ref([]);
    const showTradeTab = ref(false);

    const changeView = (view) => {
      if (currentView.value !== 'Home') {
        history.value.push(currentView.value);
      }
      currentView.value = view;
      emit('updateView', view);
    };

    const goBack = () => {
      if (history.value.length > 0) {
        const lastView = history.value.pop();
        currentView.value = lastView;
      } else {
        currentView.value = 'Home';
      }
    };

    onMounted(async ()=>{
      showTradeTab.value = await TradeService.hasPeerToPeerEnabled();
    })

    return {
      currentView,
      showTradeTab,
      changeView,
      goBack
    };
  }
};


</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

#homePage {
  padding-bottom: calc(66px + env(safe-area-inset-bottom));
}

.bottom-nav {
  display: flex;
  justify-content: space-around;
  padding: 7px 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}

button {
  background: none;
  border: none;
  color: #453A94;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button.active {
  color: #8D7EFD;
}

.home-button img {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

button span {
  font-size: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
</style>
