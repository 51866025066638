<template>
  <div class="modal" v-if="show">
    <div class="modal-content">
      <div v-if="failed" style="font-size: 16px; font-weight: 800; color: red;">
        Transaction failed!
      </div>
      <div v-else>
        <span class="close" @click="closeBuyModal">&times;</span>
        <h1>Are you Sure?</h1>
        <div class="recommended-units-card">
          <div class="left-unit-card">
            <h1>{{ card.actualTraded }} Units</h1>
            <div class="time_date">
              <div class="date-time">
                <img src="../../images/date.svg" alt="Date" class="icon">
                <p>{{card.startDate ?  new Intl.DateTimeFormat("en-US", {
                  day: "numeric",
                  month: "short",
                }).format(new Date(parseInt(card.startDate, 10))) :""}}</p>
              </div>
              <div class="date-time">
                <img src="../../images/clock.svg" alt="Clock" class="icon">
                <p>{{ card?.time }}</p>
              </div>
            </div>
            <p class="you_save">{{ "You save ₹300 on this purchase"  }}</p>
          </div>
          <div class="right-unit-card">
            <p class="price">{{ card.amount }}</p>
            <p>per unit ₹{{ card.unitPrice ? card?.unitPrice:"5" }}/-</p>
            <button v-if="!loading" @click="confirmAction">{{ showbuy ? 'Buy' : 'Sell' }}</button>
            <button v-else >loading...</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TradeService from "@/services/trade.service";
import { useToast } from 'vue-toastification'; 

export default {
  props: {
    card: Object,
    selectedDay: String,
    show: Boolean,
    showbuy: Boolean,
  },
  data() {
    return {
      loading: false,
      failed: false,
    };
  },
  async mounted() {
    console.log(this.card,"logging card");
  },
  methods: {
    async buyOrSell(actionType) {
      this.loading = true;
      try {
        const response = await TradeService.updateP2P(this.card, actionType);
        if(response){
          this.showToast(``, 
                       `${actionType =="buy" ? "Bought" :"Sold"} Sucessfully.`);
        }
          this.loading = false;
          this.$router.push({ path: '/transaction', query: { savedMoney: "300" } });
      } catch (e) {
        this.failed = true;
        this.showToast("Transaction Failed", "An error occurred during the transaction. Please try again.");
        throw new Error(e);
      } 
    },
    confirmAction() {
      const actionType = this.showbuy ? 'buy' : 'sell';
      this.buyOrSell(actionType);
    },
    showToast(title, message) {
      const toast = useToast(); 
      toast.success(message, {
        title: title,
        timeout: 1000, 
        position: 'top-right',
      });
    },
    closeBuyModal() {
      this.$emit('close');  
    }
  }
};
</script>



<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: white;
  padding: 20px 10px;
  border-radius: 5px;
  width: 375px;
}
.modal-content h1{
  font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color: #453A94;
    font-weight: 600;
}
.close {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}
.recommended-units-card{
    display: flex;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 15px 15px;
    border-radius: 15px;
}
.time_date{
    display: flex;
    align-items: center;
    gap:12px;
    margin-top: 12px;

}
.time_date p{
    margin: 0;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color:#000000;

}
.left-unit-card h1{
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color:#000000;
    text-align: left;
    font-weight: 700;
}
.you_save{
    color: #00A86C;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 12px;
    text-align: left;

}
.right-unit-card{
  background: linear-gradient(to bottom, #FFFFFF, #E6E6E6);
  border-radius: 15px;
  padding: 6px 10px;
;
}
.right-unit-card p:nth-child(1){
    font-size: 16px;
    color:#453A94;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin: 0;



}
.right-unit-card p:nth-child(2){
    font-size:12px;
    color:#656565;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;


}
.right-unit-card button{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    background-color: #453A94;
     border-radius: 40px;
     color:#ffffff;
     text-transform: uppercase;
     padding: 4px 30px;
     margin-top: 18px;

}

.purchase_card_sell{
  display: flex;
  justify-content: space-between;
  gap: 30px; 
  border-radius: 15px;
  padding: 24px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover; 
  width: 100%; 

}
.purchase_card {
  display: flex;
  gap: 30px; 
  border-radius: 15px;
  padding: 24px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover; 
  width: 100%; 
}

.purchase_card div {
  display: flex;
  flex-direction: column; 
  font-size: 12px;
  color: #ffffff;
}

.purchase, .save {
  margin: 0;
  font-size: 12px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;

}
.date-time{
  display: flex;
  gap:5px;
}
</style>