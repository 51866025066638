<template>
    <div class="page-content">
      <header class="wallet-header" @click="close">
        <img src="../../images/back.png" alt="Back Button" class="back-button" />
        <h1>Transactions</h1>
      </header>
  
      <div class="transaction-history">
        <h2>Transaction History</h2>
        <div v-if="loading">Loading....</div>
        
        <div v-if="transactions.length === 0 && !loading" class="empty-state">
          <img src="../../images/no_transaction.png" alt="No Transactions" class="empty-icon" />
          <p>No transactions found.. </p>
        </div>
      
        <div v-else v-for="(transaction, index) in transactions" :key="index" class="recommended-units-card">
          <div class="left-unit-card">
            <h1>{{ transaction?.actualTraded}} Units</h1>
            <div class="time_date">
              <div class="date-time">
                <img src="../../images/date.svg" alt="Date">
                <p>{{ transaction?.startDate
                  ? new Intl.DateTimeFormat("en-US", {
                      day: "numeric",
                      month: "short",
                    }).format(new Date(parseInt(transaction.startDate, 10)))
                  : "" }}</p>
              </div>
              <div class="date-time">
                <img src="../../images/clock.svg" alt="Clock">
                <p>{{ transaction?.time }}</p>
              </div>
            </div>
            <p class="you_save">{{ transaction?.saveMessage ? transaction.saveMessage :"You save ₹300 on this purchase" }}</p>
          </div>
          <div class="right-unit-card">
            <p class="price">{{ transaction.amount }}</p>
            <p >per unit ₹{{ transaction?.unitPrice ? transaction?.unitPrice :"5" }}/-</p>
            <p class="transaction_status">{{ transaction.status  }}</p> 
          </div>
        </div>
      </div>
      <button v-if="!loading && transactions.length !== 0"  class="close-page" @click="close">Close</button>
      </div>
  </template>
  
  <script>
  import UserService from "@/services/user.service";
  import TradeService from "@/services/trade.service";
  
  export default {
    // props: {
    //   isVisible: {
    //     type: Boolean,
    //     default: false,
    //   },
    // },
    data() {
      return {
        walletBalance: '',
        transactions: [],
        loading:true
      };
    },
    async mounted() {
       try {
        this.loading=true;
        this.walletBalance = await UserService.getWalletBalance();
         this.transactions = await TradeService.listTransactionHistory();
         if(this.transactions.length>0){
           this.transactions = this.transactions.map(transaction => {
             transaction.time = this.getTimeString(transaction.startDate, transaction.endTDate);
              return transaction;
           })
         }
        this.loading=false;
       } catch (error) {
        console.log(error);
       }
     
    },
    methods: {
      close() {
        this.$emit('close');
      },
    getTimeString(startDate, endDate) {
    if (!startDate || !endDate) return "";
    const options = {
        hour: "numeric",
        minute: "numeric",
        hour12: true, 
    };
    const startTime = new Intl.DateTimeFormat("en-US", options).format(new Date(parseInt(startDate, 10)));
    const endTime = new Intl.DateTimeFormat("en-US", options).format(new Date(parseInt(endDate, 10)));
    return `${startTime} - ${endTime}`;
},
    },
  };
  </script>
  
  
  <style scoped>
  .page-content {
    padding: 20px 0px;
    margin: 0 auto;
    max-width: 600px;
  }
  
  .wallet-header {
    display: flex;
    gap:10px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 999;
  }
  
  .wallet-header h1 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  
  .back-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .transaction-history {
    margin-top: 20px;
  }
  
  .transaction-history h2 {
    color: #453a94;
    text-align: left;
    font-size: 16px;
  }
  
  .recommended-units-card{
    display: flex;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 15px 15px;
    border-radius: 15px;
    margin-bottom: 10px;
}
.time_date{
    display: flex;
    align-items: center;
    gap:12px;
    margin-top: 12px;

}
.time_date p{
    margin: 0;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color:#000000;

}
.left-unit-card h1{
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color:#000000;
    text-align: left;
    font-weight: 700;
}
.you_save{
    color: #00A86C;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 12px;
    text-align: left;

}
.right-unit-card{
  background: linear-gradient(to bottom, #FFFFFF, #E6E6E6);
  border-radius: 15px;
  padding: 6px 10px;
;
}
.right-unit-card p:nth-child(1){
    font-size: 16px;
    color:#453A94;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin: 0;



}
.right-unit-card p:nth-child(2){
    font-size:12px;
    color:#656565;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;


}
.right-unit-card button{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    background-color: #453A94;
     border-radius: 40px;
     color:#ffffff;
     text-transform: uppercase;
     padding: 4px 30px;
     margin-top: 18px;

}

.purchase_card_sell{
  display: flex;
  justify-content: space-between;
  gap: 30px; 
  border-radius: 15px;
  padding: 24px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover; 
  width: 100%; 

}
.purchase_card {
  display: flex;
  gap: 30px; 
  border-radius: 15px;
  padding: 24px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover; 
  width: 100%; 
}

.purchase_card div {
  display: flex;
  flex-direction: column; 
  font-size: 12px;
  color: #ffffff;
}

.purchase, .save {
  margin: 0;
  font-size: 12px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;

}
.date-time{
  display: flex;
  gap:5px;
}
  .close-page {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #453a94;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .transaction_status{
  border-radius:5px !important;
  background-color:rgb(220, 159, 46) !important;
  color:#ffffff;
  margin: 0;
  font-size: 13px;
  padding: 2px;
}
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  text-align: center;
  color: #999;
}

.empty-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}
.empty-state p {
  font-size: 14px;
  color: #666;
  font-family: 'Montserrat', sans-serif;
}

  </style>
  