import ApiCoreService from "./api.core.service";
import UserService from "@/services/user.service";

export default class TradeService extends ApiCoreService {
    static get token() {
        const token = localStorage.getItem('_token'); // Fetch from localStorage
        return token ? token : null;
    }

    static get userId() {
        const userId = localStorage.getItem('_id'); // Fetch from localStorage
        return userId ? userId : null;
    }

    static async getSellInfo(englishDuration) {
        try {
            const d = await this.graphCall('sell', `{
              withAuth(token: "${this.token}"){
                listP2PMeter(criteria: "{\\"user\\":\\"[[me]]\\"}"){
                  id
                  no
                  user{id name{str} props{prop val propType}}
                  meterType
                  averageDailyProduction
                  averageDailyExport
                  averageDailyConsumption
                  averageBuyPrice
                  averageSellPrice
                  boundDiscomCode
                  ratePerUnit
                  props{prop val propType}
                  node{
                    id name{str} cohorts{id name{str} props{prop val propType}}
                  organizations{id addresses{careOf line1 line2 line3 city state pin country{name{str}}}}
                  contactName{str}
                  nodeType{id name{str} code}
                  props{prop val propType}
                  hardwareGroups{
                    id name{str} description{str} groupType{id name{str} code description{str}}  devices{
                      id connectedTo{id} uid hardwareType{ id typeName code model{str} description{str} 
                        brand{
                          id name{str} code description{str}
                        }
                        category{id name{str} description{str} code}
                        props{prop val propType}
                      }
                    }
                  }
                }
                  }
              
                listP2PListing(criteria:"{\\"type\\": \\"BUY\\", \\"user\\":{\\"$ne\\": \\"[[me]]\\"}, \\"status\\":\\"UNCLAIMED\\"}" limit:10000){
                  id
                  type
                  units
                  amount
                  startDate
                  endTDate
                  status
                  actualTraded
                  moneyToSeller
                  moneyToTrustLayer
                  moneyToDiscom
                  moneyToBap
                  moneyToBpp
                  buyerOrg{id name{str}}
                  counterNode{id code name{str}}
                  actualTraded
                  seller{id addresses{careOf line1 line2 line3 city state country{id code} pin} name{str}}
                  user{id name{str} address{careOf line1 line2 line3 city state pin country{id name{str} code}}}
                }
              
              }
                
            }
            
            mapper <safejs- 
            data.sell = {};
            data.sell.pricePerUnit =data.withAuth.listP2PMeter.length ? data.withAuth.listP2PMeter[0].ratePerUnit : 5;
            data.sell.maxValuePerDay = Math.ceil(data.withAuth.listP2PMeter.length ? data.withAuth.listP2PMeter[0].averageDailyExport : 0);
            data.sell.recommends = data.withAuth.listP2PListing;
            -js>   
            `);
            console.log(d);
            return d;
        } catch (c) {
            throw new Error(c);
        }
    }

    static async getPurchaseInfo(englishDuration) {
        try {
            const d = await this.graphCall('buy', `{
              withAuth(token: "${this.token}"){
                listP2PMeter(criteria: "{\\"user\\":\\"[[me]]\\"}"){
                  id
                  no
                  user{id name{str} props{prop val propType}}
                  meterType
                  averageDailyProduction
                  averageDailyExport
                  averageDailyConsumption
                  averageBuyPrice
                  averageSellPrice
                  boundDiscomCode
                  ratePerUnit
                  props{prop val propType}
                  node{
                    id name{str} cohorts{id name{str} props{prop val propType}}
                  organizations{id addresses{careOf line1 line2 line3 city state pin country{name{str}}}}
                  contactName{str}
                  nodeType{id name{str} code}
                  props{prop val propType}
                  hardwareGroups{
                    id name{str} description{str} groupType{id name{str} code description{str}}  devices{
                      id connectedTo{id} uid hardwareType{ id typeName code model{str} description{str} 
                        brand{
                          id name{str} code description{str}
                        }
                        category{id name{str} description{str} code}
                        props{prop val propType}
                      }
                    }
                  }
                }
                  }
              
                listP2PListing(criteria:"{\\"type\\": \\"SELL\\", \\"user\\":{\\"$ne\\": \\"[[me]]\\"}, \\"status\\":\\"UNCLAIMED\\"}" limit:10000){
                  id
                  type
                  units
                  amount
                  startDate
                  endTDate
                  status
                  actualTraded
                  moneyToSeller
                  moneyToTrustLayer
                  moneyToDiscom
                  moneyToBap
                  moneyToBpp
                  buyerOrg{id name{str}}
                  counterNode{id code name{str}}
                  actualTraded
                  seller{id addresses{careOf line1 line2 line3 city state country{id code} pin} name{str}}
                  user{id name{str} address{careOf line1 line2 line3 city state pin country{id name{str} code}}}
                }
              
              }
                
            }
            
            mapper <safejs- 
            data.buy = {};
            data.buy.pricePerUnit =data.withAuth.listP2PMeter.length > 0  ? data.withAuth.listP2PMeter[0].ratePerUnit : 5;
            data.buy.maxValuePerDay = Math.ceil(data.withAuth.listP2PMeter.length ? data.withAuth.listP2PMeter[0]?.averageDailyConsumption : 0);
            data.buy.recommends = data.withAuth.listP2PListing;
            -js>   
            `);
            console.log(d);
            return d;
        } catch (c) {
            throw new Error(c);
        }
        // return new Promise(resolve => {
        //     setTimeout(() => resolve(
        //         {
        //             pricePerUnit: 4,
        //             maxValuePerDay: 100,
        //             recommends: [
        //                 {
        //                     ts: +new Date(),
        //                     units: 200,
        //                     date: +new Date(),
        //                     time: '02:30 - 4:30PM',
        //                     saveMessage: 'You save ₹400 on this purchase',
        //                     price: 1000.00,
        //                     unitPrice: 5,
        //                     availableUnits: 200,
        //
        //                 },
        //                 {
        //                     ts: +new Date(),
        //                     units: 150,
        //                     date: +new Date(),
        //                     time: '01:30 - 3:30PM',
        //                     saveMessage: 'You save ₹300 on this purchase',
        //                     price: 750.00,
        //                     unitPrice: 5,
        //                     availableUnits: 150,
        //
        //                 },
        //                 {
        //                     ts: +new Date(),
        //                     units: 100,
        //                     date: +new Date(),
        //                     time: '03:30 - 5:30PM',
        //                     saveMessage: 'You save ₹200 on this purchase',
        //                     price: 500.00,
        //                     unitPrice: 5,
        //                     availableUnits: 200,
        //                 }
        //             ]
        //         }
        //     ), 1);
        // });
    }

    static async getAutoPurchaseInfo() {
        try {
            return await this.graphCall('withAuth.listP2PAutoTradeMandate', `
           {
            withAuth(token: "auto"){
            listP2PAutoTradeMandate(criteria: "{\\"user\\":\\"[[me]]\\", \\"mType\\": \\"BUY\\"}") {
             id
             user{id name{str} props{prop val propType}}
             rate
             percentUnits
             actualUnits
             startDate
             endDate
             days
                 }
             }
            }
            `, {});
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
       
    }

    static async getAutoSellInfo() {
        console.log((() => {
            const e = {};
            Error.captureStackTrace(e, e);
            return e.stack.split('\n')[2].trim();
        })());
        try {
            return await this.graphCall('withAuth.listP2PAutoTradeMandate', `
           {
            withAuth(token: "auto"){
            listP2PAutoTradeMandate(criteria: "{\\"user\\":\\"[[me]]\\", \\"mType\\": \\"SELL\\"}") {
             id
             user{id name{str} props{prop val propType}}
             rate
             percentUnits
             actualUnits
             startDate
             endDate
             days
                 }
             }
            }
            `, {});
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    }


    static async addTransaction(cardDetails, selectedDay) {
        return new Promise(resolve => {
            setTimeout(() => resolve(
                {
                    success: true,
                    message: 'Added Transaction successfully.'
                }
            ), 1);
        });
    }

    static async listTransactionHistory() {
      try {
        const node = (await UserService.getCurrentUserNode()).shift()?.node?.id;
        return await this.graphCall(
          "withAuth.listP2PListing",
          ` {
            withAuth(token: "auto"){
             listP2PListing(criteria:"{\\"$or\\": [{ \\"node\\": \\"${node}\\" },{ \\"counterNode\\": \\"${node}\\" }]}" limit:10000){
                  id
                  type
                  units
                  amount
                  startDate
                  endTDate
                  status
                  actualTraded
                  moneyToSeller
                  moneyToTrustLayer
                  moneyToDiscom
                  moneyToBap
                  moneyToBpp
                  buyerOrg{id name{str}}
                  counterNode{id code name{str}}
                  actualTraded
                  seller{id addresses{careOf line1 line2 line3 city state country{id code} pin} name{str}}
                  user{id name{str} address{careOf line1 line2 line3 city state pin country{id name{str} code}}}
                }
             }
            }`
        );
      } catch (error) {
        throw new Error(error);
      }
    }

    static async updateP2P(data,type){
      try {
        const node = (await UserService.getCurrentUserNode()).shift()?.node?.id;
        return await this.graphCall(
          "mutationWithAuth",
          `mutation{
           mutationWithAuth(token:"${this.token}"){
                update{
                   updateP2PListing(
                       id :"${data.id}",
                       counterNode:"${node}",
                       status:CLAIMED,
      ){
        id
      }
      }
    }
  }`
        );
      } catch (error) {
        throw new Error(error);
      }

    }
    
   

    static async saveAutoPurchase(data) {
        try {
            const daysEnumArray = data.days.map(day => day).join(", ");
            return await this.graphCall(
              "mutationWithAuth",
              `mutation{
               mutationWithAuth(token:"${this.token}"){
                    fabricate{
                       createP2PAutoTradeMandate(
                           user:"${this.userId}",
                           mType:BUY,
                           rate:${data.rate},
                           percentUnits:${data.units},
                           startDate:"${data.startDate}",
                           endDate:"${data.endDate}",
                           days:[${daysEnumArray}],   
          ){
            id
            days
          }
          }
        }
      }`
            );
          } catch (error) {
            throw new Error(error);
          }
      }

    static async saveAutoSell(data) {
        try {
            const daysEnumArray = data.days.map(day => day).join(", ");
            return await this.graphCall(
              "mutationWithAuth",
              `mutation{
               mutationWithAuth(token:"${this.token}"){
                    fabricate{
                       createP2PAutoTradeMandate(
                           user:"${this.userId}",
                           mType:SELL,
                           rate:${data.rate},
                           percentUnits:${data.units},
                           startDate:"${data.startDate}",
                           endDate:"${data.endDate}",
                           days:[${daysEnumArray}],   
          ){
            id
            days
          }
          }
        }
      }`
            );
          } catch (error) {
            throw new Error(error);
          }
      }


      static async modifyAutoPurchase(data,id) {
        try {
            const daysEnumArray = data.days.map(day => day).join(", ");
            return await this.graphCall(
              "mutationWithAuth",
              `mutation{
               mutationWithAuth(token:"${this.token}"){
                    update{
                       updateP2PAutoTradeMandate(
                           id :"${id}"
                           user:"${this.userId}",
                           mType:BUY,
                           rate:${data.rate},
                           percentUnits:${data.units},
                           startDate:"${data.startDate}",
                           endDate:"${data.endDate}",
                           days:[${daysEnumArray}],   
          ){
            id
            days
          }
          }
        }
      }`
            );
          } catch (error) {
            throw new Error(error);
          }
      }

      static async modifyAutoSell(data,id) {
        try {
            const daysEnumArray = data.days.map(day => day).join(", ");
            return await this.graphCall(
              "mutationWithAuth",
              `mutation{
               mutationWithAuth(token:"${this.token}"){
                    update{
                       updateP2PAutoTradeMandate(
                           id :"${id}"
                           user:"${this.userId}",
                           mType:SELL,
                           rate:${data.rate},
                           percentUnits:${data.units},
                           startDate:"${data.startDate}",
                           endDate:"${data.endDate}",
                           days:[${daysEnumArray}],   
          ){
            id
            days
          }
          }
        }
      }`
            );
          } catch (error) {
            throw new Error(error);
          }
      }


      

    static async hasPeerToPeerEnabled(){
        const user = await UserService.getCurrentUser();
        return !!(user.props || []).find(p => (p.prop === 'hasP2P' && p.val === 'true'));
    }


}
